<template>
  <div>
    <!--begin::Users-->
    <div v-if="clientSelected" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <Users></Users>
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <Roles></Roles>
      </div>
    </div>
    <!--end::Users-->
    <div v-if="!clientSelected" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <b-alert variant="danger" show>
          {{ $t("general.selectClientFirst") }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Users from "@/components/Tenants/Users/Table";
import Roles from "@/components/Admins/Roles/Table";

export default {
  name: "TenantsUsersIndex",
  components: {
    Users,
    Roles
  },
  computed: {
    clientSelected: function () {
      return this.$store.getters.selectedClient !== null;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.tenantUserAdministration"),
        route: ""
      },
      {
        title: this.$t("general.overview"),
        route: "/tenant/users"
      }
    ]);
  }
};
</script>
